import { combineReducers } from 'redux';
import { all, race, call, take, fork } from 'redux-saga/effects';
import { CollectionsReducersEntries, Collections, CollectionsSagas, Collection } from './types';
import { autoRestart } from '../../utils/sagas';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
// collections
import data from './data';
import modals from './modals';
import pendings from './pendings';

const collections: Collections = {
    data,
    modals,
    pendings,
};

// ActionTypes
export const types = {
    CLEAR_STATE: 'root/CLEAR_STATE',
};

// ActionCreators
export const actions = {
    clearState: () => ({ type: types.CLEAR_STATE }),
};

export const history = createBrowserHistory();

const collectionsReducers: CollectionsReducersEntries = Object.keys(collections)
    .map((key) => collections[key])
    .reduce(
        (reducersEntries, collection: Collection) =>
            collection.reducer
                ? {
                      ...reducersEntries,
                      [collection.name]: collection.reducer,
                  }
                : reducersEntries,
        {},
    );

const collectionsSagas: CollectionsSagas = Object.keys(collections)
    .map((key) => collections[key])
    .reduce(
        (sagas, collection) => (collection.saga ? [...sagas, autoRestart(collection.saga, collection.name, 2)] : sagas),
        [],
    );

const createRootReducer = () => {
    const combinedReducer = combineReducers({
        router: connectRouter(history),
        ...collectionsReducers,
    });

    const rootReducer = (state: any, action: any) => {
        if (action.type === types.CLEAR_STATE) {
            state = {};
        }
        return combinedReducer(state, action);
    };

    return rootReducer;
};

const forkedCollectionsSagas = collectionsSagas.map((saga) => fork(saga));
function* rootSaga() {
    while (true) {
        yield race({
            sagas: call(function* () {
                yield all(forkedCollectionsSagas);
            }),
            clear: take(types.CLEAR_STATE),
        });
    }
}

export { createRootReducer, rootSaga };
export default collections;

import { typeSignIn } from '../../services/signin/types';
import { findPendingOperation } from './utils';

export const name: string = 'pendings';

export const types = {
    START_PENDING_OPERATION: name + '/START_PENDING_OPERATION',
    STOP_PENDING_OPERATION: name + '/STOP_PENDING_OPERATION',
    CLEAR_ALL: name + '/CLEAR_ALL',
};

export interface PendingType {
    type: string;
    count: number;
}

export interface PendingOperationStateState {
    actions: PendingType[];
}

const initialState: PendingOperationStateState = {
    actions: [],
};

function isActionAlreadyExist(state: PendingOperationStateState, type: string): boolean {
    return !!state.actions.find((el) => el.type === type);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.START_PENDING_OPERATION:
            let findAction: boolean = isActionAlreadyExist(state, action.payload.type);
            if (findAction) {
                return {
                    ...state,
                    actions: state.actions.map((item, index) => {
                        if (action.payload.type === item.type) {
                            return {
                                ...item,
                                count: item.count + 1,
                            };
                        }
                        return item;
                    }),
                };
            } else {
                return {
                    ...state,
                    actions: [...state.actions, { type: action.payload.type, count: 1 }],
                };
            }
        case types.STOP_PENDING_OPERATION:
            const foundAction = state.actions.find((el) => el.type === action.payload.type);
            if (foundAction && foundAction.count > 1) {
                return {
                    ...state,
                    actions: state.actions.map((item, index) => {
                        if (action.payload.type === item.type) {
                            return {
                                ...item,
                                count: item.count - 1,
                            };
                        }
                        return item;
                    }),
                };
            } else {
                return {
                    ...state,
                    actions: state.actions.filter((el) => el.type !== action.payload.type),
                };
            }
        case types.CLEAR_ALL:
            return initialState;
        default:
            return state;
    }
};
export default reducer;

const localState = (state: any): PendingOperationStateState => state[name] || initialState;
export const selectors = {
    actions: (state) => localState(state).actions,
    isAppLoading: (state): boolean => selectors.actions(state).length >= 1,

    isLogging: (state): boolean => findPendingOperation(selectors.actions(state), typeSignIn.LOGIN),
    isGettingSignInOTP: (state): boolean => findPendingOperation(selectors.actions(state), typeSignIn.GET_OTP),
};

import { createStore as createEnhancedStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import AppConfig from './appConfig';
import { createRootReducer, rootSaga, history } from '../core/modules';
import * as Sentry from '@sentry/react';
// import { getHistory } from '../utils/historyUtils';
import { routerMiddleware } from 'connected-react-router';

const isDev: boolean = process.env.NODE_ENV === 'development';

const createReduxStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [sagaMiddleware];

    const routerMiddle: any = routerMiddleware(history);
    middlewares.push(routerMiddle);

    let composeEnhancer = compose;
    const sentryReduxEnhancer = AppConfig.useSentry ? Sentry.createReduxEnhancer() : null;
    // const analyticsReduxEnhancer = AppConfig.useAnalytics ? Sentry.createReduxEnhancer() : null;

    if (isDev) {
        // deactivate eslint rule so that it doesn't complain from 'requires' below
        /* eslint global-require: 0 */
        if (AppConfig.useReduxDevTools) {
            composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        }

        if (AppConfig.useReduxLogger) {
            const createLoggerMiddleware = require('./reduxDevLogger').default;
            middlewares.push(createLoggerMiddleware());
        }
    } else {
    }

    const store = createEnhancedStore(
        createRootReducer(),
        sentryReduxEnhancer
            ? composeEnhancer(applyMiddleware(...middlewares), sentryReduxEnhancer)
            : composeEnhancer(applyMiddleware(...middlewares)),
    );

    sagaMiddleware.run(rootSaga);
    return store;
};

export default {
    createReduxStore,
};

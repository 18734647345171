export const paths = {
    activate: '/activate',
    askPhone: '/askPhone',
    captcha: '/captcha',
    detect: '/detect',
    changePhone: '/changePhone',
    concur: '/concur',
    email: '/email',
    emailActivate: '/signup/email/activate/:userid/:emailid',
    forgot: '/forgot',
    forgotPassword: '/forgotPassword/:userid/:authkey',
    home: '/home',
    joinGroup: '/joingroup/:groupid/',
    otp: '/otp',
    password: '/password',
    root: '/',
    setPassword: '/setPassword',
    signup: '/signup',
    portal: '/clients/:clientid',
    test: '/test',
    validatePhone: '/validatePhone',
};

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import configs from './index';

const sentryIgnoreErrors: string[] = [
    // Ignore Safari browser errors
    'ResizeObserver loop limit exceeded',
];

export function initializeSentry(): void {
    if (configs.appConfig.useSentry) {
        const sentryDns: string = process.env.REACT_APP_SENTRY_DNS;
        Sentry.init({
            dsn: sentryDns,
            integrations: [new Integrations.BrowserTracing()],
            release: `auth-webapp@${process.env.REACT_APP_VERSION}`,
            environment: process.env.REACT_APP_ENVIRONMENT,
            // for now all errors are remoted, adjust the ratio for finer control
            tracesSampleRate: 0.2,
            ignoreErrors: sentryIgnoreErrors,
            maxValueLength: 8000,
        });
    }
}

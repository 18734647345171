import { all, call, put, take, select, actionChannel, takeEvery, delay } from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import { types, actions as modalActions, selectors as modalSelectors } from './module';

const TRANSITION_DELAY_BETWEEN_POPUP = 600;

// channel with buffer to handle all created modals
function* queueModals() {
    const requestChan = yield actionChannel(types.OPEN_MODAL, buffers.expanding());
    while (true) {
        const { payload } = yield take(requestChan);
        yield call(displayModalSaga, payload);
    }
}

// display modal
function* displayModalSaga(payload) {
    const modalsLength = yield select(modalSelectors.modalsLength);
    if (modalsLength > 0) {
        yield put(modalActions.displayModal());
    }
}

// add a timeout transition when displaying many modals
function* onModalRemoved() {
    const modalsLength = yield select(modalSelectors.modalsLength);
    if (modalsLength > 0) {
        yield delay(TRANSITION_DELAY_BETWEEN_POPUP);
        yield put(modalActions.displayModal());
    }
}

export default function* modalSaga() {
    yield all([call(queueModals), takeEvery(types.CLOSE_MODAL, onModalRemoved)]);
}

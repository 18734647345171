import appConfig from './appConfig';
import storeConfig from './reduxStoreConfig';
import envConfig from './currentEnv';

const configs = {
    appConfig,
    storeConfig,
    envConfig,
};

export default configs;

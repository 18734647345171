import React from 'react';
import * as Sentry from '@sentry/react';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import AppContainer from './navigation/appContainer';
import { client } from './core/services/helpers';
import configs from './config/';

const isDev: boolean = configs?.appConfig?.isDev;
export const store = configs.storeConfig.createReduxStore();

const App = (): React.ReactElement => {
    // Add surrounding errorBoundary
    return (
        <Sentry.ErrorBoundary fallback={<div>An error has occured</div>}>
            <ReduxProvider store={store}>
                <QueryClientProvider client={client}>
                    <AppContainer />
                    {isDev && <ReactQueryDevtools initialIsOpen={false} />}
                </QueryClientProvider>
            </ReduxProvider>
        </Sentry.ErrorBoundary>
    );
};

export default App;

import { getLanguage } from '../../utils/language';
import stringsFR from './strings.fr';
import stringsEn from './strings.en';

export const language: string = getLanguage();

export function rps(stringToReplace: string, variables: Object): string {
    let res = stringToReplace;
    Object.keys(variables).forEach((key) => {
        res = res.replace(`{{${key}}}`, '' + variables[key]);
    });
    return res;
}

export default language === 'en' ? stringsEn : stringsFR;

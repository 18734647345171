// Module Name
export const name = 'signin';

export const typeSignIn = {
    LOGIN: name + '/LOGIN',
    VERIFY_OTP: name + '/VERIFY_OTP',
    SET_PASSWORD: name + '/SET_PASSWORD',
    FORGOT_PASSWORD: name + '/FORGOT_PASSWORD',
    SET_NEW_PASSWORD: name + '/SET_NEW_PASSWORD',
    GET_OTP: name + '/GET_OTP',
};

import stringsRes from '../resources/strings';
import { toast } from 'react-toastify';

export const toastError = (message: string, defaultText: boolean = true) => {
    toast.error(defaultText ? stringsRes.login.error.default + ' (' + message + ')' : message);
};

export const toastInfo = (message: string) => {
    toast.info(message);
};

import stringsRes from '../resources/strings';
import { ReactQueryError } from '../core/apiTypes';

export const logError = (error: ReactQueryError) => {
    console.log(error, error.response.status);
};

export const getDefaultError = (error: ReactQueryError) => {
    return stringsRes.login.error.default + ' (' + error.response?.status + ')';
};

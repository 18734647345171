import { take, select, race, fork, join, cancel, call, cancelled, delay } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

export function* waitForTriggerOrCancelTask(starType, stopType, forkedGenerator, ...params) {
    while (true) {
        yield take(starType);
        const task = yield fork(forkedGenerator, ...params);
        yield take(stopType);
        yield cancel(task);
    }
}

export function* selectAndWaitIfNeeded(
    selector, // some sub-state selectof
    type, // action that might have triggered this sub-state change
    isValueValid: (selectorValue: any) => any = (value) => value, // determines, using sub-state, if we should still wait (default: identity function)
) {
    let selectorValue;
    let waitingForValidValue = true;
    while (waitingForValidValue) {
        // fork a take to avoid missing actions during select check
        const takeTask = yield fork(take, type);
        selectorValue = yield select(selector);
        waitingForValidValue = !isValueValid(selectorValue);
        if (waitingForValidValue) {
            yield join(takeTask);
        } else {
            yield cancel(takeTask);
        }
    }
    return selectorValue;
}

export function* watchEvents(onInitEventChannel, onEvent, onEventError?, params?) {
    const channel = eventChannel(onInitEventChannel);
    try {
        while (true) {
            const event = yield take(channel);
            if (event.error) {
                if (onEventError) {
                    yield call(onEventError, event.error, params);
                }
            } else {
                if (onEvent) {
                    // we use a timeout here because in case of timeout error (socket.io) call(onEvent) never
                    // resigned and watchevent get stucked here...
                    yield race({
                        processEvent: call(onEvent, event, params),
                        timeout: delay(5000),
                    });
                }
            }
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

export function* listenIfTriggered(
    startType, // action we're waiting for
    stopType, // action that will stop
    listeningTaskGenerator, // action we should launch
) {
    while (true) {
        yield take(startType);
        yield race({
            stopListening: take(stopType),
            listeningTask: call(listeningTaskGenerator),
        });
    }
}

export function* listenIfSelectedOrTriggered(selector, startType, stopType, listeningTaskGenerator) {
    while (true) {
        // either wait for action, or just take state
        yield selectAndWaitIfNeeded(selector, startType);
        yield race({
            stopListening: take(stopType),
            listeningTask: call(listeningTaskGenerator),
        });
    }
}

/**
 * Restarts a specific number of times the generator passed in argument.
 * @param generator the generator that will be restarted @maxRestartCount times.
 * @param maxRestartCount the maximum attempts generator will be restarted.
 */
export function autoRestart(generator, name, maxRestartCount) {
    return function* autoRestarting(...args) {
        let restartIndex = 0;
        while (restartIndex < maxRestartCount) {
            try {
                // console.warn(`${restartIndex === 0 ? "starting" : "restarting"} saga ` + name);

                yield call(generator, args);
                break;
            } catch (e) {
                console.warn(`Unhandled error in '${generator.name}'`, e);

                // increment counter
                restartIndex++;
            }
        }
        // saga might finish without error
        if (restartIndex >= maxRestartCount) {
            console.warn('You have reach the maximum restart attempt for ' + name);
        }
    };
}

export default {
    selectAndWaitIfNeeded,
    watchEvents,
    listenIfTriggered,
    listenIfSelectedOrTriggered,
};

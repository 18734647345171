const appConfig = {
    useCrashReporter: process.env.NODE_ENV !== 'development',
    useReduxDevTools: process.env.NODE_ENV === 'development' && true,
    useReduxLogger: process.env.NODE_ENV === 'development' && true,
    useAnalytics: process.env.NODE_ENV !== 'development' && true,
    useSentry: process.env.NODE_ENV !== 'development' && true,
    isDev: process.env.NODE_ENV === 'development',
};

export default appConfig;

import { useEffect, useState, useRef } from 'react';

export default function useKeyboardActive(): boolean {
    const [keyboardShown, setKeyboardShown] = useState(false);
    const baseHeight = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setKeyboardShown(window.visualViewport.height < baseHeight.current);
        };
        if (window.visualViewport) {
            baseHeight.current = window.visualViewport.height;
            window.visualViewport.addEventListener('resize', handleResize);
        }
        return () => {
            if (window.visualViewport) {
                window.visualViewport.removeEventListener('resize', handleResize);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return keyboardShown;
}

export default {
    blue: '#1542C3',
    lightBlue: '#4570EA',
    darkBlue: '#193996',
    paleGrey: ' #EEEEEE',
    fillGrey: '#D1D1D1',
    lightGrey: '#F4F4F6',
    white: '#FFFFFF',
    grey: '#606060',
    primaryBlack: '#000000',
    secondaryBlack: '#333333',
    carmin: '#8C0B0B',
    //
    black: '#2F313F',
    lightBlack: '#8C8E97',
    brightGray: '#F5F4F6',
    lightGray: '#EFEFEF',
    darkGray: '#B9B9B9',
    iconGrey: '#BFC0CB',
    labelGrey: '#74767D',
    lecab: '#1542C3',
    lecabDarker: '#153DB1',
    red: '#DC0000',
    green: '#009229',
};
